<template>
  <div>
    <Assign ref="AssignRef"
            @poster="poster" />
    <el-row :gutter="10"
            class="border_top">
      <el-col :span="20">
        <AssignForm ref="AssignFormRef"
                    @validate="validate"
                    v-show="visibility"
                    key='assign'
                    :isShow_to="isShow_to"
                    :showClass="false" />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import Assign from '../../components/global_check_group_or_user.vue'
// import AssignForm from './assignform.vue'
import AssignForm from './form.vue'
import { createPaper } from '@/api/IntelligentTest.js'
import { checkTime } from '@/utils/date.js'
export default {
  data () {
    return {
      is_research: Number(window.localStorage.getItem('is_research')),
      // 当Assign 组件中学生选择了数据时，不显示试卷可见字段
      isShow_to: true,
      visibility: false
    }
  },

  components: {
    Assign, AssignForm
  },
  methods: {
    /*定义拿到数据的方法，Assign传给父组件再传给AssignForm */
    poster (isShow_to) {
      this.isShow_to = isShow_to
    },

    initForm () {
      let today = new Date()
      let year = today.getFullYear()
      let form1 = {}
      let form = {}

      this.$http({
        url: '/api/v1/public/get_record',
        method: 'get',
        params: {
          module_id: 7
        }
      }).then(res => {
        if (res.data && res.data.list.length != 0) {
          // console.log('ddd')
          form1 = res.data.list
        }
        form = {
          name: form1.name || '',
          // questionDistrict: form1.questionDistrict || '',
          questionSubject: form1.questionSubject || '',
          // source_id: form1.source_id || '',
          questionType: [],
          questionCategory: '',
          check_point_id: form1.checkpoints || [],
          questionYearBegin: form1.questionYearBegin || (year - 1) + '',
          questionYearEnd: form1.questionYearEnd || year + '',
          paper_name: form1.paper_name || '',
          checkpoints2: form1.checkpoints2 || [],
          questionNumber: 5,
          paper_time: form1.paper_time || '',
          edu_paper_type: form1.edu_paper_type || '',
          grade_id: form1.grade_id || '',
          paper_duration: form1.paper_duration || '',
          question_difficulty: form1.question_difficulty || '',
          end_time: form1.end_time || '',
          edu_paper_correct_type: form1.edu_paper_correct_type || '1',
          select_which: form1.select_which || '0'
        }
        // this.$refs.AssignFormRef.form = form
        if (form1.questionDistrict && !Array.isArray(form1.questionDistrict)) {
          form.questionDistrict = [form1.questionDistrict]
        } else {
          form.questionDistrict = form1.questionDistrict || []
        }
        if (form1.source_id && !Array.isArray(form1.source_id)) {
          form.source_id = [form1.source_id]
        } else {
          form.source_id = form1.source_id || []
        }
        if (form.end_time && !checkTime(form.end_time, 1)) {
          form.end_time = ''
        }
        this.$set(this.$refs.AssignFormRef, 'form', form)
        this.$refs.AssignFormRef.initData()

      })
    },

    async validate (form) {
      this.form = form
      let list = this.$refs.AssignRef.checkStudentArr
      if (list.length == 0 && !this.is_research) {
        this.$message.warning('请选择指定的学生或者分组')
        return
      }
      if (list.length > 0) {
        let user_ids = []
        let organize_ids = []
        list.forEach(item => {
          if (item.organize_id) {
            organize_ids.push(item.organize_id)
          } else {
            user_ids.push(item.id)
          }
        });
        if (organize_ids.length) {
          organize_ids = [...new Set(organize_ids)]
        }
        form.organize_ids = organize_ids
        form.user_ids = user_ids
      }
      try {
        const res = await createPaper(form)
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.$refs.AssignFormRef.loadings = false
        this.$router.push(`/testPapercomposition/IntelligentTest/info?type=smart&edu_paper_id=${res.data.edu_paper_id}`)
      } catch (err) {
        this.$refs.AssignFormRef.loadings = false

        console.log(err)
      }
    }
  },
  mounted () {
    this.initForm()
    this.visibility = true
  },
  activated () {
    this.visibility = true
  },
  deactivated () {
    this.visibility = false
  }
}
</script>

<style lang="scss" scoped>
.border_top {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}
</style>