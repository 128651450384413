<template>
  <div>
    <el-form :model="form"
             style="width:600px"
             label-width="100px">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="指定个人:">
            <el-input placeholder="请输入"
                      v-model="form.keyword"
                      @input="searchStudent"
                      clearable
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="指定分组:">
            <el-select v-model="form.group"
                       style="width:100%"
                       multiple
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in group"
                         :key="item.organize_id"
                         :label="item.name"
                         :value="item.organize_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-table border
                  :stripe="true"
                  ref="multipleTable"
                  highlight-current-row
                  :row-key="getRowKeys"
                  :max-height="$store.state.tableHeight / 2"
                  :data="tableData"
                  @selection-change="handleSelectionChange"
                  style="width: 100%">
          <el-table-column type="selection"
                           :reserve-selection="true"
                           width="55" />
          <el-table-column prop="truename"
                           align="center"
                           label="真实姓名" />
          <el-table-column prop="verifiedMobile"
                           align="center"
                           label="手机号" />
        </el-table>
      </el-row>
      <p style="font-size:18px;font-weight:bold;line-height:50px"><strong>已选择学生：</strong></p>
      <el-table border
                :stripe="true"
                highlight-current-row
                :max-height="$store.state.tableHeight / 2"
                :data="checkStudentArr"
                style="width: 100%">
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />
        <el-table-column align="center"
                         label="操作">
          <template slot-scope="scope">
            <el-button type="text"
                       style="color:red"
                       @click="delRow(scope.row,scope.$index)"
                       v-if="!scope.row.organize_id">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        keyword: '',
        group: ''
      },
      // 筛选学生列表
      tableData: [],
      checkStudentArr: [],
      group: [],
      groupUser: [],
      selectUser: [],
      isShow_to: true
    }
  },

  props: {
    //接收父亲传来的方法，主要用于拿到此组件的数据
    poster: {
      type: Function
    },
  },

  mounted () {
    this.getGroupApi()
  },
  watch: {
    'form.group': {
      handler (newVal, oldVal) {
        if (newVal.length < oldVal.length) {
          console.log(7)
          let delId = []
          oldVal.forEach(item => {
            if (!newVal.includes(item)) {
              delId.push(item)
            }
          })
          for (let index = 0; index < this.groupUser.length; index++) {
            let item = this.groupUser[index]
            if (delId.includes(item.organize_id)) {
              this.groupUser.splice(index, 1)
              index -= 1
            }
          }
          this.sortArr()
        } else {
          this.getUserByGroup(newVal[newVal.length - 1])
        }

      },
      deep: true
    },
    checkStudentArr: {
      handler () {
        for (let index = 0; index < this.checkStudentArr.length; index++) {
          let item = this.checkStudentArr[index]
          for (let j = index + 1; j < this.checkStudentArr.length; j++) {
            let row = this.checkStudentArr[j]
            if (item.id == row.id) {
              if (!item.organize_id) {
                this.$refs.multipleTable.toggleRowSelection(item, false)
              }
              if (!row.organize_id) {
                this.$refs.multipleTable.toggleRowSelection(row, false)
              }
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    /*调用方法传递数据*/
    setBrotherMsg () {
      this.$emit('poster', this.isShow_to)
    },
    getRowKeys (row) {
      return row.id
    },
    handleSelectionChange (value) {
      this.selectUser = value.map(item => {
        if (!item.time) {
          item.time = Date.now()
        }
        return item
      })
      this.sortArr()
    },
    sortArr () {
      var list = [...this.selectUser, ...this.groupUser]
      let obj = {}
      list.forEach(item => {
        obj[item.id] = item
      })
      let keys = Object.keys(obj).sort((a, b) => a - b)
      let arr = []
      keys.forEach(item => {
        arr.push(obj[item])
      })
     

      this.checkStudentArr = arr
      if (this.checkStudentArr.length > 0) {
        this.isShow_to = false
        this.setBrotherMsg()
      } else {

        this.isShow_to = true
        this.setBrotherMsg()

      }
    },
    searchStudentApi () {
      return this.$http({
        url: '/api/v1/special/search_student',
        method: 'get',
        params: {
          keyword: this.form.keyword
        }
      })
    },
    getGroupApi () {
      this.$http({
        url: '/api/v1/organize/lst',
        method: 'get',
        params: {
          page: 1,
          limit: 1000,
        }
      }).then(res => {
        this.group = res.data.list
      })
    },
    searchStudent () {
      if (this.form.keyword == '') {
        clearTimeout(this.setTimeId)
        this.tableData = []
        return
      }
      if (this.setTimeId) {
        clearTimeout(this.setTimeId)
      }
      this.setTimeId = setTimeout(async () => {
        const { data } = await this.searchStudentApi()
        this.tableData = data
        this.setTimeId = 0
      }, 1000);
    },
    getUserByGroup (id) {
      this.$http({
        url: '/api/v1/organize/user_list',
        method: 'get',
        params: {
          organize_id: id
        }
      }).then(res => {
        var index = 0
        res.data.list.forEach(item => {
          item.organize_id = id
          item.time = Number(Date.now()) + index
          this.groupUser.push(item)
          index += 1
        })
        console.log(this.groupUser)
        this.sortArr()
      })
    },
    delRow (row) {
      this.$refs.multipleTable.toggleRowSelection(row, false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>